<template>
  <div class="font-montserrat d-flex flex-column choose-role-content">
    <div class="child-header mrg-bottom-16">
      Create an account as a
    </div>
    <b-button
      class="custom-btn-style mrg-bottom-16"
      variant="none"
      @click="selectRole(ROLES.BRAND)"
    >
      <span> Brand </span>
    </b-button>
    <b-button
      class="custom-btn-style mrg-bottom-16"
      variant="none"
      @click="selectRole(ROLES.RETAILER)"
    >
      <span> Retailer </span>
    </b-button>
  </div>
</template>
<script>
import { AUTH_REGISTER_CHILDREN } from '@/router/routes/pages'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'
import { RESET_SIGNUP_DETAILS } from '@/store/modules/signup.module'

const { ROLES } = constants

export default {
  name: 'ChooseRole',
  components: {
    BButton,
  },
  props: {
    routeQueryParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      ROLES,
    }
  },
  computed: {
    ...mapState({
      signupDetails: state => state.signup.signupDetails,
    }),
  },
  created() {
    // Reset the previous signup data in store
    this.$store.commit(RESET_SIGNUP_DETAILS)
    // Checking is referral
    if (this.routeQueryParams.referrerCode) {
      if (this.routeQueryParams.referrerCode.charAt(0) === 'R') {
        this.selectRole(ROLES.BRAND)
      }
      if (this.routeQueryParams.referrerCode.charAt(0) === 'B') {
        this.selectRole(ROLES.RETAILER)
      }
    }
  },
  methods: {
    selectRole(role) {
      this.signupDetails.role = role
      this.$router.push({ name: AUTH_REGISTER_CHILDREN.ADD_USER })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
</style>
